/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useStore } from '../store';
import { SpecificationStyle } from './specification-style';
import { initSpecification } from '../initWidgets';

export const Specification = (props) => {
  const { state } = useStore();

  const keyFigureWidget = useRef();

  useEffect(() => {
    if (keyFigureWidget?.current?.parentNode) {
      initSpecification({ keyFigureWidget: keyFigureWidget.current, ric: state.user.selectedRic, state });
    }
    window.keyFigures.setRic(state.user.selectedRic === '.FTSE' ? 'LSE.L' : state.user.selectedRic);
    window.keyFigures.setLocaleMessages(state.user.defaultLanguage, state.template.widgets.WKeyFigures.localeMessages);
  }, [state.user.selectedRic]);
  return (
    <React.Fragment>
      <SpecificationStyle theme={state.template.theme} />
      <div className="columns">
        <div className="column">
          <div className="key-figures-widget dark-box">
            <div className="tile is-parent">
              <article className="tile is-child box">
                {/* <p className="widget-title">Key Figure</p> */}
                <div className="content1">
                  <div ref={keyFigureWidget}>{props.children} </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
