// /* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Suspense } from 'react';
import ReactMarkdown from 'react-markdown';

import { Layout } from './Layout';
// import { useStore } from './store';
import WHeatmap from './docs/WHeatmap.md';

// const markdown = `
//   # Header 1
//   ## Header 2

//   _ italic _

//   ** bold **

//   <b> bold Html </b>
//   `;

export const Docs = () => {
  // const { state, dispatch } = useStore();
  const [markdown, setMarkdown] = useState(null);
  useEffect(() => {
    fetch(WHeatmap)
      .then((res) => res.text())
      .then((md) => {
        setMarkdown(md);
      });
  }, []);
  return (
    <Layout>
      <section className="hero is-link">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-desktop">
              <div className="column">{/* <p className="sub-title">Logged in user: {state.user.email}</p> */}</div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero">
        <div className="hero-body">
          <div className="container white-container">
            {/* <div class="tabs">
        <ul>
          <li class="is-active">
            <a>Heatmap</a>
          </li>
          <li>
            <a>Instrument Info</a>
          </li>
        </ul>
      </div> */}
            <ReactMarkdown source={markdown} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

// import { Route, BrowserRouter as Router, Link, useRouteMatch } from 'react-router-dom';
// import WidgetMarkdowns from './docs/Index';

// export const Widgets = () => {
//   // const { state, dispatch } = useStore();
//   const [markdown, setMarkdown] = useState(null);
//   const match = useRouteMatch('/documentation/:widgetname');
//   useEffect( () => {
//     const loadWidget = async () => {
//       if (match) {
//         var type = match.params.widgetname; // example variable - will change from user input
//         const WidgetToRender = WidgetMarkdowns[type];
//         console.dir(WidgetToRender)
//         // const fetchWidgetData = await fetch(WidgetToRender);
//         // const WidgetData = await fetchWidgetData.text()
//         // console.log(WidgetData)
//         // setMarkdown(WidgetData)
//         // fetch(match.params.widgetname).then((res) => res.text()).then((md) => {
//         //   setMarkdown(md)
//         //   })
//       }
//     }
//     loadWidget();

//   }, []);

//   var type = 'WHeatmap'; // example variable - will change from user input
//   const WidgetToRender = WidgetMarkdowns[type];

//   return <WidgetToRender/>

// };

// export const Docs = () => {
//   return (
//     <Router>
//       <div class="tabs is-medium">
//         <ul>
//           <li>
//             <Link to={`/documentation/WHeatmap`}>Heatmap</Link>
//           </li>
//           <li>
//             <Link to={`/documentation/WInstrumentInfo`}>Instrument Info</Link>
//           </li>
//         </ul>
//       </div>

//       <Route path="/documentation">
//         <Widgets />
//       </Route>
//     </Router>
//   );
// };
