/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useStore } from '../store';
import { MarketTilesStyle } from './market-tiles-style';
import { initMarketTiles } from '../initWidgets';

export const MarketTiles = (props) => {
  const { state } = useStore();
  const marketTiles = useRef();

  useEffect(() => {
    if (marketTiles.current.parentNode) {
      window.marketTilesWidget = window.fdc__widgets__.WMarketTiles.init(marketTiles.current.parentNode);
      window.marketTilesWidget.setIndexRic(['.FTSE', '.DJI', '.IXIC', '.SPX']);
    }
  }, [state.user.selectedRic]); // state.user.selectedRic

  return (
    <React.Fragment>
      <MarketTilesStyle theme={state.template.theme} />

      <div className="column">
        <div className="main-index-widget dark-box">
          <article className="tile is-child box">
            <div className="content1">
              <div ref={marketTiles}>{props.children} </div>
            </div>
          </article>
        </div>
      </div>
    </React.Fragment>
  );
};
