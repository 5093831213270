/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useStore } from './store';
import { Ticker } from './components/ticker';
import { MarketTiles } from './components/market-tiles';
import { MarketList } from './components/marketlist';

import { HeatMap } from './components/heatmap';
import { Trends } from './components/trends';
import { fetchThemes } from './curd';
import { Layout } from './Layout';

import { useLocation } from 'react-router-dom';
import { MainIndex } from './components/main-index';

export const MarketOverview = () => {
  const { state, dispatch } = useStore();

  return (
    <Layout advanceNav={true}>
      <section className="hero is-link">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-desktop">
              <div className="column">
                <p className="sub-title">Logged in user: {state?.user?.email}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <Ticker ricList={'marketOverviewRics'} children="Loading..." />
            <Trends children="Loading..." />
            <MarketList children="Loading..." />
          </div>
          <div className="container padding-top-80">
            <HeatMap children="Loading..." />
          </div>

          <div className="container padding-top-20">
            <MainIndex children="Loading..." />
          </div>
          <div className="container padding-top-20">
            <MarketTiles children="Loading..." />
          </div>
        </div>
      </section>
    </Layout>
  );
};
