/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { firestore } from './firebase';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import { Layout } from './Layout';
import { useStore } from './store';
import { useHistory } from 'react-router-dom';

export const AddNewTheme = (props) => {
  const history = useHistory();
  const { state, dispatch } = useStore();
  const jsonEditor = useRef();
  const [saved, setSaved] = useState(false);
  const [templateName, setTemplateName] = useState(state.playground.templateName);
  const [template, setTemplate] = useState(state.playground);

  useEffect(() => {
    if (jsonEditor?.current?.parentNode) {
      jsonEditor.current.set(template);
    }
  }, [template]);

  const saveNewTemplate = async ({ templateName, template, createdBy }) => {
    console.log(templateName, createdBy);
    const db = await firestore;

    /* CREATING NEW TEMPLATE IN DB */
    const newTemplate = await db.collection('template').doc(templateName);
    await newTemplate.set({ ...template, templateName, createdBy });

    /* CREATING NEW THUMBNAIL IN DB */
    const user = await firestore.collection('users').doc(createdBy);
    const getUser = await firestore.collection('users').doc(createdBy).get();
    const userData = await getUser.data();
    const thumbnailObject = [{ image: template.logoUrl, name: templateName }];
    const setToArray = [...userData.themes, ...thumbnailObject].filter(
      (thing, index, self) => index === self.findIndex((t) => t.name === thing.name)
    );
    user.set({
      ...userData,
      themes: setToArray
    });
    dispatch({
      type: 'SAVE_NEW_TEMPLATE',
      user: {
        ...userData,
        themes: setToArray
      },
      template: { ...template, templateName, createdBy }
    });
    await history.push('/');
    return template.name;
  };

  return (
    <Layout>
      <style jsx="true">{`
        // .jsoneditor {
        //   border: thin solid ${state.template.theme.borderColor} !important;
        // }

        // .jsoneditor-menu {
        //   color: ${state.template.theme.color} !important;
        //   background-color: ${state.template.theme.background} !important;
        //   border-bottom: 1px solid ${state.template.theme.borderColor} !important;
        // }
        // .jsoneditor-contextmenu .jsoneditor-menu button {
        //   color: ${state.template.theme.color} !important;
        // }
        // .jsoneditor-contextmenu .jsoneditor-menu button:hover,
        // .jsoneditor-contextmenu .jsoneditor-menu button:focus {
        //   color: ${state.template.theme.color} !important;
        //   background-color: ${state.template.theme.backgroundHover} !important;
        // }

        .item1 { grid-area: header; }
        
        .item1:hover,.item2:hover {
          background: ${template.theme.backgroundHover} !important;;
        }

        .item3 { grid-area: main; }
        .grid-container {
          display: grid;
          grid-template-areas:
            'header'
            'main';
          grid-gap: 10px;
          background-color: #2196F3;
          padding: 10px;
          margin-top: 88px;
          border-radius: 4px;
          cursor: not-allowed;
        }
        
        .grid-container > div {
          background-color: rgba(255, 255, 255, 0.8);
          text-align: center;
          padding: 20px 0;
        
        }
      `}</style>
      <section className="hero is-link">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-desktop">
              <div className="column">
                <p className="sub-title">Logged in user: {state?.user?.email}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero">
        <div className="hero-body">
          <div className="container white-container">
            <div className="columns">
              <div className="column">
                <div className="field is-grouped is-grouped-left">
                  <label>{templateName && !saved && <label>Template Name: {templateName}</label>}</label>
                </div>
                {saved && (
                  <div className="notification is-primary">
                    <strong>{templateName}</strong> saved successfully
                  </div>
                )}
                <div className="field is-grouped is-grouped-left">
                  <div className="control">
                    <input
                      className="input"
                      value={templateName}
                      onChange={(e) => {
                        setTemplateName(e.target.value);
                        setSaved(false);
                      }}
                      type="text"
                      placeholder="Template Name"
                    />
                  </div>
                  <div className="control">
                    <a
                      onClick={async () => {
                        const isSaved = await saveNewTemplate({ templateName, template, createdBy: state.user.email });
                        setSaved(true);
                      }}
                      className="button is-link">
                      Save Template
                    </a>
                  </div>
                </div>
                <Editor
                  ref={jsonEditor}
                  value={template}
                  onChange={(value) => setTemplate(value)}
                  statusBar={false}
                  mode="form"
                  allowedModes={['tree', 'view', 'form', 'code', 'text']}
                  ace={ace}
                  theme="ace/theme/github"
                  htmlElementProps={{
                    style: {
                      height: 500
                    }
                  }}
                />
              </div>
              <div className="column is-one-quarter">
                <div className="grid-container">
                  <div
                    className="item1"
                    style={{
                      background: template.theme.background,
                      color: template.theme.color,
                      border: `2px solid ${template.theme.borderColor}`
                    }}>
                    Brand
                  </div>
                  <div
                    className="item2"
                    style={{ background: template.theme.background, border: `2px solid ${template.theme.borderColor}` }}>
                    <svg
                      style={{ width: '100px', color: template.theme.upTick }}
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z"></path>
                    </svg>

                    <svg
                      style={{ width: '100px', color: template.theme.downTick }}
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
