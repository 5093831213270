/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useStore } from '../store';
import { InstrumentInfoStyle } from './instrumentinfo-style';
import { initInstrumentInfo } from '../initWidgets';

export const InstrumentInfo = (props) => {
  const { state } = useStore();
  const instrumentInfoWidget = useRef();

  useEffect(() => {
    if (instrumentInfoWidget.current.parentNode) {
      initInstrumentInfo({ instrumentInfoWidget: instrumentInfoWidget.current, ric: state.user.selectedRic });
    }
    window.instrumentInfo.setLocaleMessages(state.user.defaultLanguage, state.template.widgets.WInstrumentInfo.localeMessages);
    window.instrumentInfo.setRic(state.user.selectedRic === '.FTSE' ? 'LSE.L' : state.user.selectedRic);
  }, [state.user.selectedRic]);
  return (
    <React.Fragment>
      <InstrumentInfoStyle theme={state.template.theme} />
      <div className="columns">
        <div className="column">
          <div className="instrument-info-widget dark-box">
            <div className="tile is-parent">
              <article className="tile is-child box">
                <div className="content1">
                  <div ref={instrumentInfoWidget}>{props.children} </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
