import React from 'react';
export class MainIndexStyle extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <style jsx="true">
        {' '}
        {`
          .main-index-widget .w-main-index-widget table tr td {
            color: ${theme.color};
            border-top: 1px solid ${theme.borderColor};
          }
          .main-index-widget.widget-xs .widget-body .chart-container,
          .main-index-widget.widget-sm .widget-body .chart-container {
            border: 1px solid ${theme.borderColor};
          }
        `}
      </style>
    );
  }
}
