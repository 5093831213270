/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home } from './Home';
// import { TickerCharts } from './TickerCharts';
import { MarketOverview } from './MarketOverview';
import { CompanyOverview } from './CompanyOverview';
import { Docs } from './Docs';
import { AddNewTheme } from './AddNewTheme';

export const Dashboard = (props) => {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/add">
            <AddNewTheme />
          </Route>
          <Route path="/documentation">
            <Docs />
          </Route>
          <Route path="/market-overview">
            <MarketOverview />
          </Route>
          <Route path="/company-overview">
            <CompanyOverview />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
      {props.children}
    </Router>
  );
};
