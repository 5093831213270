import React from 'react';
export class TimeSalesStyle extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <style jsx="true">
        {' '}
        {`
          .time-sales-widget .ag-theme-balham .ag-header-cell,
          .time-sales-widget .ag-theme-balham .ag-header-group-cell {
            color: ${theme.color};
          }
          .time-sales-widget .ag-theme-balham .ag-header {
            color: ${theme.color};

            background-color: ${theme.background};
          }
          .time-sales-widget .ag-theme-balham .ag-row-even {
            background-color: ${theme.background};
          }
          .time-sales-widget .ag-theme-balham .ag-row-odd {
            background-color: ${theme.background};
          }

          .time-sales-widget .ag-theme-balham .ag-cell {
            color: ${theme.color};
          }
          .dark-box .box,
          .widget-wrapper .widget-body {
            background-color: ${theme.background};
            color: ${theme.color};
            font-weight: bold;
          }

          .heatmap-widget .lessDiv,
          .heatmap-widget .vless {
            color: ${theme.downTick};
          }
          .heatmap-widget .greaterDiv,
          .heatmap-widget .vgreater {
            color: ${theme.upTick};
          }
          .heatmap-widget.dark-box .tile.is-parent {
            padding: 0 !important;
          }
          .ag-cell,.ag-column-hover,.ag-column-row {
            background-color: ${theme.background};
            color: ${theme.color};
          }
        `}
      </style>
    );
  }
}
