/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useStore } from '../store';
import { OrderBookStyle } from './orderbook-style';
import { initOrderBook } from '../initWidgets';

export const OrderBook = (props) => {
  const { state } = useStore();
  const orderbook = useRef();

  useEffect(() => {
    if (orderbook?.current?.parentNode) {
      initOrderBook({ orderBookWidget: orderbook.current, ric: state.user.selectedRic });
    }
    window.orderbook.setRic(state.user.selectedRic === '.FTSE' ? 'LSE.L' : state.user.selectedRic);
  }, [state.user.selectedRic]);
  return (
    <React.Fragment>
      <OrderBookStyle theme={state.template.theme} />
      <div className="columns">
        <div className="column">
          <div className="orderbook-widget dark-box">
            <div className="tile is-parent">
              <article className="tile is-child box">
                {/* <p className="widget-title">Orderbook</p> */}
                <div className="content1">
                  <div ref={orderbook}>{props.children} </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
