import React from 'react';
export class MarketListStyle extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <style jsx="true">
        {' '}
        {`
        .dark-cell {
          background-color: ${theme.background};
          color: ${theme.color} !important;
        }
        .dark-box .box, .widget-wrapper .widget-body {
        background-color: ${theme.background};
        color: ${theme.color};
        font-weight: bold;
      }
      
      }
    `}
      </style>
    );
  }
}
