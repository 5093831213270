// store.js
import React, { createContext, useContext, useReducer } from 'react';

const StoreContext = createContext();
const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_APPLICATION_DATA_SUCCESS':
      return {
        ...state,
        ...action.data,
        loading: false,
        isError: false
      };
    case 'UPDATED_RIC':
      return {
        ...state,
        user: {
          ...state.user,
          selectedRic: action.ric
        }
      };
    case 'UPDATED_TEMPLATE':
      return {
        ...state,
        template: action.newTemplate
      };
    case 'UPDATED_EDIT_TEMPLATE':
      return {
        ...state,
        playground: action.getPlayground
      };
    case 'SAVE_NEW_TEMPLATE':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user
        },
        template: {
          ...state.template,
          ...action.template
        }
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};

export const useStore = () => useContext(StoreContext);
