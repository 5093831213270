import React from 'react';
export class PriceChartStyle extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <style jsx="true">
        {' '}
        {`
          .dark-box .box,
          .widget-wrapper .widget-body {
            background-color: ${theme.background};
            color: ${theme.color};
            font-weight: bold;
          }
          .price-chart-widget .v-chart .highcharts-background,
          .price-chart-widget .v-stock-chart .highcharts-background {
            fill: ${theme.background};
          }
          .price-chart-widget .v-widget-control-bar {
            background-color: ${theme.background};
            font-family: 'Fira Sans', sans-serif;
            padding-bottom: 0.625rem;
            padding-top: 0;
            border: 1px solid ${theme.borderColor};
          }
          .price-chart-widget .w-price-chart-widget .v-stock-chart .highcharts-xaxis-grid .highcharts-grid-line {
            stroke-width: 1px;
          }
          .price-chart-widget .v-chart .highcharts-grid-line,
          .price-chart-widget .v-stock-chart .highcharts-grid-line {
            fill: none;
            stroke: ${theme.background};
          }
          .price-chart-widget .w-price-chart-widget {
            border: 1px solid ${theme.borderColor};
          }
          .price-chart-widget .w-price-chart-widget .v-stock-chart .highcharts-plot-border {
            stroke: ${theme.background};
            stroke-width: 1px;
          }
          .price-chart-widget .v-chart .highcharts-column-series rect.highcharts-point,
          .price-chart-widget .v-stock-chart .highcharts-column-series rect.highcharts-point {
            stroke: ${theme.background};
          }
        `}
      </style>
    );
  }
}
