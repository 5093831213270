import React from 'react';
export class InstrumentInfoStyle extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <style jsx="true">
        {' '}
        {`
      .instrument-info-widget .w-instrument-info-widget {
        border: 1px solid ${theme.borderColor};
      }
      .dark-box .box, .widget-wrapper .widget-body {
        background-color: ${theme.background};
        color: ${theme.color};
      }

       .instrument-info-widget .lessDiv,
       .instrument-info-widget .vless {
        color: ${theme.downTick};
      }
      .instrument-info-widget .greaterDiv,
      .instrument-info-widget .vgreater {
        color: ${theme.upTick};
      }
      }
    `}
      </style>
    );
  }
}
