/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useStore } from '../store';
import { TimeSalesStyle } from './time-sales-style';
import { initTimeSales } from '../initWidgets';

export const TimeSales = (props) => {
  const { state } = useStore();
  const timeSalesWidget = useRef();

  useEffect(() => {
    if (timeSalesWidget?.current?.parentNode) {
      initTimeSales({ timeSalesWidget: timeSalesWidget.current, ric: state.user.selectedRic });
    }
    window.timeSalesWidget.setRic(state.user.selectedRic === '.FTSE' ? 'LSE.L' : state.user.selectedRic);
  }, [state.user.selectedRic]);
  return (
    <React.Fragment>
      <TimeSalesStyle theme={state.template.theme} />
      <div className="columns">
        <div className="column">
          <div className="timeSales-widget dark-box">
            <div className="tile is-parent">
              <article className="tile is-child box">
                <div className="content1">
                  <div ref={timeSalesWidget}>{props.children} </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
