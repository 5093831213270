/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useStore } from '../store';
import { HeatMapStyle } from './heatmap-style';
import { initHeatMap } from '../initWidgets';

export const HeatMap = (props) => {
  const { state } = useStore();
  const heatMapWidget = useRef();

  useEffect(() => {
    if (heatMapWidget?.current?.parentNode) {
      initHeatMap({ heatMapWidget: heatMapWidget.current, ric: state.user.selectedRic });
    }
    window.heatmapWidget.setIndexRic(state.user.selectedRic);
  }, [state.user.selectedRic]);
  return (
    <React.Fragment>
      <HeatMapStyle theme={state.template.theme} />

      <div className="column">
        <div className="heatmap-widget dark-box">
          <div className="tile is-parent">
            <article className="tile is-child box">
              <div className="content1">
                <div ref={heatMapWidget}>{props.children} </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
