/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useStore } from './store';
import { Ticker } from './components/ticker';
import { Specification } from './components/specification';

import { InstrumentInfo } from './components/instrumentinfo';
import { OrderBook } from './components/orderbook';
import { TimeSales } from './components/time-sales';

// import { fetchThemes } from './curd';
import { Layout } from './Layout';
import { PriceChart } from './components/pricechart';
import { CorporateEvent } from './components/corporate-event';

export const CompanyOverview = () => {
  const { state, dispatch } = useStore();

  return (
    <Layout advanceNav={true}>
      <section className="hero is-link">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-desktop">
              <div className="column">
                <p className="sub-title">Logged in user: {state?.user?.email}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero">
        <div className="hero-body">
          <div className="container"></div>
          <div className="container">
            <Ticker ricList={'companyOverviewRics'} children="Loading..." />
            <InstrumentInfo children="Loading.." />
            <OrderBook children="Loading.." />
            <Specification children="Loading.." />
            <PriceChart children="Loading.." />
            <CorporateEvent children="Loading.." />
          </div>
          <div className="container padding-top-20">
            <TimeSales children="Loading..." />
          </div>
        </div>
      </section>
    </Layout>
  );
};
