/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useStore } from '../store';
import { CorporateEventStyle } from './corporate-event-style';
import { initCorporateEvent } from '../initWidgets';

export const CorporateEvent = (props) => {
  const { state } = useStore();
  const corporateEventWidget = useRef();

  useEffect(() => {
    if (corporateEventWidget?.current?.parentNode) {
      initCorporateEvent({
        corporateEventWidget: corporateEventWidget.current,
        ric: state.user.selectedRic
      });
    }
    window.corporateEvent.setRic(state.user.selectedRic === '.FTSE' ? 'LSE.L' : state.user.selectedRic);
  }, [state.user.selectedRic]);
  return (
    <React.Fragment>
      <CorporateEventStyle theme={state.template.theme} />
      <div className="columns">
        <div className="column">
          <div className="key-figures-widget dark-box">
            <div className="tile is-parent">
              <article className="tile is-child box">
                {/* <p className="widget-title">Key Figure</p> */}
                <div className="content1">
                  <div ref={corporateEventWidget}>{props.children} </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
