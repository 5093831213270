import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import { debugContextDevtool } from 'react-context-devtool';

import { StoreProvider } from './store';
import { Login } from './login';

const container = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <Login />
    </StoreProvider>
  </React.StrictMode>,
  container
);

// debugContextDevtool(container);
