import { firestore } from './firebase';

export const fetchUser = async ({ email }) => {
  // return USER;
  const db = await firestore.collection('users').doc(email).get();
  return db.data();
};

export const fetchSecret = async ({ widgetDoc }) => {
  // return SECRET;
  const db = await firestore.collection('secrets').doc(widgetDoc).get();
  return db.data();
};

export const fetchTemplate = async ({ theme }) => {
  // return TEMPLATE;
  const template = await firestore.collection('template').doc(theme).get();
  return template.data();
};

export const deleteTemplate = async ({ theme }) => {
  const template = await firestore.collection('template').doc(theme).delete();
  return true;
};

export const deleteTemplateInUser = async ({ email, userData }) => {
  console.log(email, userData);
  // return USER;
  let user = await firestore.collection('users').doc(email);
  await user.set(userData);
  return true;
};
