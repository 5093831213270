export const initTicker = ({ ticketNode, onChangeRic, ricList }) => {
  window.tickerBandWidget = window.fdc__widgets__.WTickerBand.init(ticketNode);
  window.tickerBandWidget.onClick(function callBack(event) {
    onChangeRic(event.ric);
  });
};
export const initSpecification = ({ keyFigureWidget, ric, state }) => {
  window.keyFigures = window.fdc__widgets__.WKeyFigures.init(keyFigureWidget);
};

export const initMarketList = ({ marketListNode, ric, state }) => {
  window.marketList = window.fdc__widgets__.WMarketList.init(marketListNode, state.template.widgets.WMarketList);
  window.marketList.setIndexRic(ric);
};

export const initInstrumentInfo = ({ instrumentInfoWidget, ric, state }) => {
  window.instrumentInfo = window.fdc__widgets__.WInstrumentInfo.init(instrumentInfoWidget, {
    ric: ric
  });
  window.instrumentInfo.setRic(ric);
};

export const initHeatMap = ({ heatMapWidget, ric, state }) => {
  window.heatmapWidget = window.fdc__widgets__.WHeatmap.init(heatMapWidget);
  window.heatmapWidget.setIndexRic(ric);
};

export const initTrends = ({ trendsWidget, ric, state }) => {
  window.trendsWidget = window.fdc__widgets__.WTrends.init(trendsWidget);
  window.trendsWidget.setRic(ric);
};

export const initMainIndex = ({ mainIndexWidget, ric, state }) => {
  window.mainIndex = window.fdc__widgets__.WMainIndex.init(mainIndexWidget);
  window.mainIndex.setView('table');
};

export const initOrderBook = ({ orderBookWidget, ric, state }) => {
  window.orderbook = window.fdc__widgets__.WOrderBook.init(orderBookWidget);
};

export const initMarketTiles = ({ marketTilesWidgets, ric, state }) => {
  window.marketTiles = window.fdc__widgets__.WMarketTiles.init(marketTilesWidgets);
};

export const initTimeSales = ({ timeSalesWidget, ric, state }) => {
  window.timeSalesWidget = window.fdc__widgets__.WTimeSales.init(timeSalesWidget);
};
export const initPriceChart = ({ priceChartWidget, ric, state }) => {
  window.priceChartWidget = window.fdc__widgets__.WPriceChart.init(priceChartWidget);
};
export const initCorporateEvent = ({ corporateEventWidget, ric, state }) => {
  window.corporateEvent = window.fdc__widgets__.WCorporateEvents.init(corporateEventWidget);
};
