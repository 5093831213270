import React from 'react';
export class TickerStyle extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <style jsx="true">{`
        .w-ticker-band-widget .ticker-element {
          border-right: 1px solid ${theme.borderColor};
          padding: 0;
          border-radius: 0;
        }
        .ticker-band-widget .w-ticker-band-widget .ticker-element {
          background: ${theme.background};
          border: 1px solid ${theme.borderColor};
        }
        .ticker-band-widget .w-ticker-band-widget .ticker-element:hover {
          background-color: ${theme.backgroundHover};
        }
        .w-ticker-band-widget .ticker-element td {
          color: ${theme.color};
          border-bottom: 1px solid ${theme.borderColor};
        }
        .w-ticker-band-widget .less,
        .w-ticker-band-widget .vless {
          color: ${theme.downTick};
        }
        .w-ticker-band-widget .greater,
        .w-ticker-band-widget .vgreater {
          color: ${theme.upTick};
        }
        .ticker-widget.dark-box {
          margin: 16px;
        }
      `}</style>
    );
  }
}
