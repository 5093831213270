/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useStore } from '../store';
import { MainIndexStyle } from './main-index-style';
import { initMainIndex } from '../initWidgets';

export const MainIndex = (props) => {
  const { state } = useStore();
  const mainIndex = useRef();

  useEffect(() => {
    if (mainIndex?.current?.parentNode) {
      initMainIndex({ mainIndexWidget: mainIndex.current, ric: state.user.selectedRic });
      window.mainIndex.setView('table');
    }
    window.mainIndex.setRic(state.user.selectedRic);
  }, [state.user.selectedRic]);
  return (
    <React.Fragment>
      <MainIndexStyle theme={state.template.theme} />

      <div className="column">
        <div className="main-index-widget dark-box">
          <article className="tile is-child box">
            <div className="content1">
              <div ref={mainIndex}>{props.children} </div>
            </div>
          </article>
        </div>
      </div>
    </React.Fragment>
  );
};
