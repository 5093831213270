/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useStore } from '../store';
import { MarketListStyle } from './marketlist-style';
import { initMarketList } from '../initWidgets';

export const MarketList = (props) => {
  const { state } = useStore();
  const marketListWidget = useRef();

  useEffect(() => {
    if (marketListWidget?.current?.parentNode) {
      initMarketList({
        marketListNode: marketListWidget.current,
        ric: state.user.selectedRic,
        state
      });
    }
    window.marketList.setIndexRic(state.user.selectedRic);
    window.marketList.setColumns(state.template.widgets.WMarketList.columns);
  }, [state.user.selectedRic]); //marketListWidget, state.user.selectedRic

  return (
    <React.Fragment>
      <MarketListStyle theme={state.template.theme} />
      <div className="columns">
        <div className="column">
          <div className="market-list-widget dark-box">
            <div className="tile is-parent ">
              <article className="tile is-child box">
                <div className="content1">
                  <div ref={marketListWidget}>{props.children} </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
