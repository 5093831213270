import React from 'react';
export class MarketTilesStyle extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <style jsx="true">
        {' '}
        {`
          .market-tiles-widget {
            height: auto;
          }
          .market-tiles-widget .w-market-tiles-widget {
            overflow: inherit;
          }
          .market-list-widget article.tile.is-child.box {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
          .market-tiles-widget .w-market-tiles-widget .tile-element .tile-header {
            background-color: ${theme.background};
            color: ${theme.color};
          }
          .market-tiles-widget .w-market-tiles-widget .tile-element .tile-header .trade-date {
            color: ${theme.color};
          }
          .market-tiles-widget .w-market-tiles-widget .tile-element .tile-body {
            background-color: ${theme.background};
            color: ${theme.color};
          }
          .market-tiles-widget .v-stock-chart .highcharts-background {
            fill: ${theme.background};
          }
          .market-tiles-widget .w-market-tiles-widget .tile-element .tile-body .chart-container .cQuoteView-chart.chart {
            border: 1px solid ${theme.borderColor};
          }
        `}
      </style>
    );
  }
}
