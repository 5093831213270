import React from 'react';
export class SpecificationStyle extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <style jsx="true">{`

       .key-figures-widget .less,
       .key-figures-widget .vless {
        color: ${theme.downTick};
      }
      .key-figures-widget .greater,
      .key-figures-widget .vgreater {
        color: ${theme.upTick};
      }
      .key-figures-widget .w-key-figures-widget table td {
        color: ${theme.color};
    }
    .key-figures-widget .w-key-figures-widget table th {
      color: ${theme.color};
      background-color: ${theme.background};
  }
   
      }
    `}</style>
    );
  }
}
