/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useStore } from '../store';
import { TrendsStyle } from './trends-style';
import { initTrends } from '../initWidgets';

export const Trends = (props) => {
  const { state } = useStore();
  const trendsWidget = useRef();

  useEffect(() => {
    if (trendsWidget?.current?.parentNode) {
      initTrends({ trendsWidget: trendsWidget.current, ric: state.user.selectedRic });
    }
    window.trendsWidget.setRic(state.user.selectedRic === '.FTSE' ? 'LSE.L' : state.user.selectedRic);
  }, [state.user.selectedRic]);
  return (
    <React.Fragment>
      <TrendsStyle theme={state.template.theme} />

      <div className="column zero-padding-tb">
        <div ref={trendsWidget}>{props.children} </div>
      </div>
    </React.Fragment>
  );
};
