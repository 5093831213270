import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBL_lkCdiY7CjWaPmNsz_-QYrriJ8Np-WU',
  authDomain: 'wealthmanagment-refinitiv.firebaseapp.com',
  databaseURL: 'https://wealthmanagment-refinitiv.firebaseio.com',
  projectId: 'wealthmanagment-refinitiv',
  storageBucket: 'wealthmanagment-refinitiv.appspot.com',
  messagingSenderId: '806636392955',
  appId: '1:806636392955:web:34b895b58ca46237837b5e'
};
// Initialize Firebase
firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
