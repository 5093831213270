/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { useStore } from './store';
import { fetchUser, fetchTemplate, fetchSecret } from './curd';

import { Dashboard } from './Dashboard';
import { create } from './createDB';
import { auth } from './firebase';

export const validateUser = async (dispatch, email) => {
  try {
    const callRead = await fetchUser({ email });
    const callTemplate = await fetchTemplate({
      theme: callRead.defaultTemplate
    });
    const callSecrets = await fetchSecret({ widgetDoc: callRead.secret });
    const isAuth = await window.dory.ServiceAuth.login(callSecrets.stream, callSecrets.key, callSecrets.path);
    dispatch({
      type: 'FETCH_APPLICATION_DATA_SUCCESS',
      data: {
        template: callTemplate,
        playground: callTemplate,
        secret: callSecrets,
        user: {
          ...callRead,
          selectedRic: callTemplate.defaultRic
        }
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const Login = () => {
  const { state, dispatch } = useStore();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [isError, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const signInWithEmailAndPasswordHandler = async (event, email, password) => {
    try {
      const user = await auth.signInWithEmailAndPassword(email, password);
      return user;
    } catch (error) {
      return error;
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };
  useEffect(() => {
    // create();
    return () => {};
  }, [state.loading]);

  if (state.isError !== true && state.user) {
    return <Dashboard />;
  }
  return (
    <React.Fragment>
      <section className="hero is-dark is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-4 is-offset-4">
              <img
                style={{
                  marginLeft: '25%'
                }}
                src="data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 157 23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M113.707 23h-3.65L97.293 10.236h14.59v2.553h-8.387L113.706 23zM111.88 0h-23v23h2.557V2.555h20.443V0zM6.23 17.17h2.264l-2.513-5.097.084-.024c1.318-.375 2.059-1.427 2.048-2.883-.018-2.118-1.332-3.333-3.602-3.333H0v11.344h2.059v-4.686h2.015l2.156 4.68v-.001zm-4.171-6.313V7.463h2.079c1.266 0 1.937.587 1.937 1.698 0 1.205-.673 1.697-2.318 1.697H2.06l-.001-.001zM16.86 12.11v-1.698h-3.94V7.531h5.402V5.828h-7.45v11.344h7.633v-1.699H12.92v-3.362h3.94v-.001zm10.314-1.698h-3.83V7.531h5.125V5.828h-7.173v11.344h2.06v-5.06h3.818v-1.7zm3.745-4.585v11.344h2.048V5.828H30.92l-.001-.001zm17.748 0v11.344h2.049V5.828h-2.05v-.001zm12.616 0h-8.058v1.699h3.008v9.645h2.059V7.527H61.3l-.016-1.699-.001-.001zm2.489 0v11.344h2.048V5.828h-2.048v-.001zm-20.484 0v8.04h-.031l-4.638-8.03h-2.17v11.344h1.92v-8.4h.03l2.534 4.416 2.28 3.974h2.002V5.828h-1.927v-.001zm32.233 0l-2.643 9.2h-.02l-2.657-9.2h-2.007l3.5 11.344h2.34l3.5-11.344H75.52z' fill='%23fff'/%3E%3C/svg%3E"
              />
              <h3 className="has-text-white">Wealth Managment Widgets</h3>
              <hr className="login-hr" />
              <div className="box">
                <form>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-medium"
                        placeholder="Enter email"
                        value={email}
                        name="email"
                        type="email"
                        onChange={(event) => onChangeHandler(event)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        type="password"
                        className="input is-medium"
                        placeholder="Enter password"
                        name="password"
                        value={password}
                        onChange={(event) => onChangeHandler(event)}
                      />
                    </div>
                  </div>

                  {isError && (
                    <div className="field">
                      <div className="control">
                        <h1> {isError} </h1>
                      </div>
                    </div>
                  )}
                  {isLoading && (
                    <div className="field">
                      <div className="control">
                        <h1> Loading .... </h1>
                      </div>
                    </div>
                  )}
                  <button
                    onClick={async (event) => {
                      event.preventDefault();
                      setLoading(true);
                      const user = await signInWithEmailAndPasswordHandler(event, email, password);
                      if (user.message) {
                        setLoading(false);
                        return setError(user.message);
                      }
                      setError(null);
                      await validateUser(dispatch, email);
                      setLoading(false);
                    }}
                    className="button is-block is-link is-large is-fullwidth">
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
