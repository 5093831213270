/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import { useStore } from './store';
import { NavLink, Link } from 'react-router-dom';

export const Layout = (props) => {
  const { state } = useStore();
  const [isActive, setisActive] = React.useState(false);
  return (
    <React.Fragment>
      <style jsx="true">{`
        html,
        body {
          background-color: ${state.template.theme.backgroundHover};
        }
        .hero.is-link,
        .button.is-link {
          background-color: ${state.template.theme.background} !important;
          border: 2px solid ${state.template.theme.borderColor} !important;
          color: ${state.template.theme.color} !important;
        }
      `}</style>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <img
                src={
                  'https://www.refinitiv.com/content/dam/marketing/en_us/images/logos/refinitiv-logo-schema.png' || state.template.logoUrl
                }
                width={112}
                height={'auto'}
              />
              | Wealth Management Widgets
            </Link>
            <a
              onClick={() => {
                setisActive(!isActive);
              }}
              role="button"
              className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div id="navbarBasicExample" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <NavLink exact={true} to="/" className="navbar-item" activeClassName="is-active">
                    Home
                  </NavLink>

                  <nav className="navbar" role="navigation" aria-label="dropdown navigation">
                    <div className="navbar-item has-dropdown is-hoverable">
                      <a className="navbar-link">Example</a>
                      <div className="navbar-dropdown">
                        <a
                          className="navbar-item"
                          target="_blank"
                          href="https://www.financial.com/demos/digital-developer-for-wealth/dark.html">
                          Dark Theme
                        </a>
                        <a
                          className="navbar-item"
                          target="_blank"
                          href="https://www.financial.com/demos/digital-developer-for-wealth/light.html">
                          Light Theme
                        </a>
                        <a
                          className="navbar-item"
                          target="_blank"
                          href="https://www.financial.com/demos/digital-developer-for-wealth/mobile.html">
                          Mobile
                        </a>
                      </div>
                    </div>
                  </nav>
                  <nav className="navbar" role="navigation" aria-label="dropdown navigation">
                    <div className="navbar-item has-dropdown is-hoverable">
                      <a className="navbar-link">Documentation</a>
                      <div className="navbar-dropdown">
                        <a
                          className="navbar-item"
                          target="_blank"
                          href="https://refinitiv-whitelabel.financial.com/?path=/story/documentation-welcome--getting-started">
                          Storybook
                        </a>
                        <a
                          className="navbar-item"
                          target="_blank"
                          href="https://www.financial.com/demos/digital-developer-for-wealth/files/Refinitiv-WidgetIntegration-TechnicalDocumentation.pdf">
                          Widget Integration - Technical Documentation
                        </a>
                        <a
                          className="navbar-item"
                          target="_blank"
                          href="https://www.financial.com/demos/digital-developer-for-wealth/files/WidgetIntegrationExample.zip">
                          Widget Integration Example
                        </a>
                        <a
                          className="navbar-item"
                          target="_blank"
                          href="https://www.financial.com/demos/digital-developer-for-wealth/files/SampleSAMLResponse.xml">
                          Sample SAML Response
                        </a>
                      </div>
                    </div>
                  </nav>

                  {/* <NavLink exact={true} to="/documentation" className="navbar-item" activeClassName="is-active">
                    Documentation
                  </NavLink> */}
                  {props.advanceNav && (
                    <React.Fragment>
                      <NavLink exact={true} to="/market-overview" className="navbar-item" activeClassName="is-active">
                        {' '}
                        Market Overview
                      </NavLink>
                      <NavLink exact={true} to="/company-overview" className="navbar-item" activeClassName="is-active">
                        Company Overview
                      </NavLink>
                    </React.Fragment>
                  )}
                  <a
                    className="navbar-item"
                    onClick={() => {
                      if (window) {
                        window.location.replace(window.location.origin);
                        window.reload();
                      }
                    }}>
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {props.children}
    </React.Fragment>
  );
};
