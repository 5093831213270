/* eslint-disable react-hooks/exhaustive-deps */
import { initTicker } from '../initWidgets';
import React, { useEffect, useRef } from 'react';
import { useStore } from '../store';
import { TickerStyle } from './ticker-style';

export const Ticker = (props) => {
  const { state, dispatch } = useStore();
  const node = useRef();
  useEffect(() => {
    if (node.current) {
      initTicker({
        ticketNode: node.current,
        onChangeRic: (ric) => dispatch({ type: 'UPDATED_RIC', ric }),
        ricList: props.ricList
      });
      console.log('Reload-Inner', props.ricList);
    }
    if (props.ricList === 'marketOverviewRics') {
      window.tickerBandWidget.setTickerElements(state.template.widgets.WTickerBand.marketOverviewRics);
    }
    if (props.ricList === 'companyOverviewRics') {
      window.tickerBandWidget.setTickerElements(state.template.widgets.WTickerBand.companyOverviewRics);
    }
  }, [props.ricList]); // dispatch, node, state.theme, state.user.selectedRic
  return (
    <React.Fragment>
      <section>
        <TickerStyle theme={state.template.theme} />

        <div className="ticker-widget dark-box">
          <div ref={node}>{props.children} </div>
        </div>
      </section>
    </React.Fragment>
  );
};
