import React from 'react';
export class OrderBookStyle extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <style jsx="true">
        {' '}
        {`
          .order-book-widget .ag-theme-balham .ag-root,
          .order-book-widget .ag-theme-balham .ag-side-bar,
          .order-book-widget .ag-theme-balham .ag-status-bar {
            border-color: ${theme.borderColor};
            border: none;
          }
          .order-book-widget .ag-theme-balham .ag-header {
            background-color: ${theme.background};
          }
          .order-book-widget .ag-theme-balham .ag-header-cell,
          .order-book-widget .ag-theme-balham .ag-header-group-cell {
            color: ${theme.color};
          }
          .order-book-widget .ag-theme-balham .ag-ltr .ag-cell {
            background: ${theme.background};
            color: ${theme.color};
          }
          .c-bar-chart .bar-chart-item .chart-bar.bar-chart-left[data-v-5863740d] {
            right: 0;
            background-color: ${theme.upTick};
        }
        .c-bar-chart .bar-chart-item .chart-bar.bar-chart-right[data-v-5863740d] {
          left: 0;
          background-color: ${theme.downTick};
      }
        `}
      </style>
    );
  }
}
