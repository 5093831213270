import React from 'react';
export class CorporateEventStyle extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <style jsx="true">
        {' '}
        {`
          .dark-box .box,
          .widget-wrapper .widget-body {
            background-color: ${theme.background};
            color: ${theme.color};
          
          }

          .corporate-events-widget .w-corporate-events-widget .v-widget-control-bar {
            background-color: ${theme.background};
            color: ${theme.color};

            border: 1px solid ${theme.borderColor};
          }
          .corporate-events-widget .form-control,
          .corporate-events-widget .form-control:focus,
          .corporate-events-widget .custom-select,
          .corporate-events-widget .w-corporate-events-widget table tr td,
          .corporate-events-widget .w-corporate-events-widget table tr th {
            color: ${theme.color};
            background-color: ${theme.background};

            border: 1px solid ${theme.borderColor};
          }
          .corporate-events-widget .w-corporate-events-widget {
            font-weight: 400 !important;
          }
        `}
      </style>
    );
  }
}
