/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Layout } from './Layout';
import { useStore } from './store';
import { fetchTemplate, deleteTemplate, fetchUser, deleteTemplateInUser } from './curd';

export const Home = () => {
  const { state, dispatch } = useStore();
  const [userInteraction, setUserInteraction] = useState({
    isLoading: false,
    selectedTemplate: state.user.defaultTemplate
  });
  const history = useHistory();
  useEffect(() => {
    return () => console.log('Home unmount');
  }, []);
  return (
    <Layout>
      <style jsx="true">{`
        .card {
          border: 10px solid ${state.template.theme.background};
          border-radius: 5px;
          position: relative;
          cursor: pointer;
        }
        .card-loading {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          padding: 10px;
          background: ${state.template.theme.background};
          color: ${state.template.theme.color};
          font-weight: bold;
        }
      `}</style>
      <section className="hero is-link">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-desktop">
              <div className="column">
                <p className="sub-title">Logged in user: {state.user.email}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero ">
        <div className="hero-body">
          <div className="container">
            <div className="container">
              <div className="columns is-multiline">
                {state.template.role === 'admin' && (
                  <div className="column is-one-quarter">
                    <Link to="/add">
                      <div className="card">
                        <div className="card-image">
                          <figure className="image is-4by3">
                            <img
                              style={{
                                margin: 'auto',
                                padding: '10px',
                                height: 'auto',
                                width: '50%'
                              }}
                              src="https://icon-library.com/images/add-new-icon/add-new-icon-29.jpg"
                              alt={'add'}
                            />
                          </figure>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}

                {state.user.themes.map((theme, index) => {
                  return (
                    <div key={`${theme.name}`} className="column is-one-quarter">
                      <div className="card">
                        <div className="card-image">
                          <figure className="image is-4by3">
                            <img
                              style={{
                                margin: 'auto',
                                padding: '10px',
                                height: 'auto'
                              }}
                              src={theme.image}
                              alt={theme.name}
                              onClick={async () => {
                                await setUserInteraction({ isLoading: true, selectedTemplate: theme.name });
                                const newTemplate = await fetchTemplate({ theme: theme.name });
                                const dispatchNewTemplate = await dispatch({ type: 'UPDATED_TEMPLATE', newTemplate });
                                await history.push('market-overview');
                                await setUserInteraction({
                                  isLoading: false
                                });
                              }}
                            />

                            <React.Fragment>
                              {state.template.templateName !== theme.name && (
                                <span
                                  onClick={async () => {
                                    const deleteTheme = await deleteTemplate({ theme: theme.name });
                                    const stateThemes = state.user.themes;
                                    const removeIndex = stateThemes.map((item) => item.name).indexOf(theme.name);
                                    await stateThemes.splice(removeIndex, 1);
                                    const distinctValuesInAandBSet = new Set(stateThemes);
                                    const setToArray = [...distinctValuesInAandBSet];
                                    console.log('setToArray', setToArray);
                                    await deleteTemplateInUser({
                                      email: state.user.email,
                                      userData: {
                                        ...state.user,
                                        ...setToArray
                                      }
                                    });
                                    const dispatchDeletedTemplate = await dispatch({
                                      type: 'SAVE_NEW_TEMPLATE',
                                      user: { themes: setToArray }
                                    });
                                  }}
                                  className="delete">
                                  X
                                </span>
                              )}
                              <span
                                onClick={async () => {
                                  await setUserInteraction({ isLoading: true, selectedTemplate: theme.name });
                                  const getPlayground = await fetchTemplate({ theme: theme.name });
                                  const updateEditTemplate = await dispatch({ type: 'UPDATED_EDIT_TEMPLATE', getPlayground });
                                  await history.push('add');
                                  await setUserInteraction({
                                    isLoading: false
                                  });
                                }}
                                className="edit">
                                Customize
                              </span>
                            </React.Fragment>
                          </figure>
                        </div>
                        {userInteraction.isLoading && userInteraction.selectedTemplate === theme.name && (
                          <div className="card-loading">Loading...</div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

// onClick={async () => {
//   await setUserInteraction({isLoading: true,selectedTemplate: theme.name});
//   const newTemplate = await fetchTemplate({theme: theme.name});
//   const dispatchNewTemplate = await dispatch({type: 'UPDATED_TEMPLATE',newTemplate});
//   await history.push('market-overview');
//   await setUserInteraction({
//     isLoading: false
//   });
// }

//     }
