import React from 'react';
export class TrendsStyle extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <style jsx="true">
        {' '}
        {`
          .dark-box .box,
          .widget-wrapper .widget-body {
            background-color: ${theme.background};
            color: ${theme.color};
            font-weight: bold;
          }

          .heatmap-widget .lessDiv,
          .heatmap-widget .vless {
            color: ${theme.downTick};
          }
          .heatmap-widget .greaterDiv,
          .heatmap-widget .vgreater {
            color: ${theme.upTick};
          }
          .widget-body.w-trends-widget {
            border: none !important;
          }
        `}
      </style>
    );
  }
}
